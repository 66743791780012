import './DecorDetails.css';
import { Link, useParams } from 'react-router-dom';
import Title from '../../../../../Title/Title';
import Data from '../../../../../../data/Models';
import ProductTitle from '../../../../../ProductTitle/ProductTitle';
import { useEffect } from 'react';

export default function DecorDetails() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { id, categoryName, subCategoryName, modelId, modelName } = useParams();
    const decor = Data.products.find((p) => p.id === parseInt(id));
    const model = Data.models.find((m) => m.id === parseInt(modelId));

    return (
        <div className='decor-details'>
            <ProductTitle categoryName={categoryName} subCategoryName={subCategoryName} productName={decor.name} modelName={modelName} path={'../../../../../'} id={modelId} />
            <div>
                <div>
                    <img src={"../../../../" + decor.ambianceImage} />
                    <div>
                        {model.products.map((product) => (
                            <Link key={product.id} to={`/DecorDetails/${categoryName}/${subCategoryName}/${modelName}/${product.id}/${modelId}`}>
                                <img src={"../../../../" + product.image} />
                            </Link>
                        ))}

                    </div>
                </div>
                <div>
                    <header>{decor.name} - {decor.code}</header>
                    <p>{decor.description}</p>
                    <Title content={"Context d'utilisation"} />
                    <div>
                        {decor.usages.map((context) => (
                            <span>{context}</span>
                        ))}
                    </div>

                </div>
            </div>
            <div>
                <div>
                    <Title content={'Spécifications techniques'} />
                    <div>
                        <div>
                            <span>Largeur :</span>
                            <span>{decor.length} </span>
                        </div>
                        <div>
                            <span>Longueur :</span>
                            <span>{decor.width} </span>
                        </div>
                        <div>
                            <span>épaisseur :</span>
                            <span>{decor.thickness} </span>
                        </div>
                        <div>
                            <span>Surface :</span>
                            <span>{decor.surface}</span>
                        </div>

                    </div>
                </div>
                <img src={"../../../../" + decor.image} />
            </div>
            <Title content={'Caractéristiques '} />
            <div>
                <img src={"../../../../" + decor.technicalSpecification} />
            </div>
        </div>
    );
}