import './SideNavbar.css';
import { useEffect, useState, useRef } from 'react';

export default function SideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // Animation trigger
    const sidebarRef = useRef(null);

    // Toggle the expanded state of a category
    const toggleCategory = (category) => {
        const newExpandedCategoryId = expandedCategoryId === category.id ? null : category.id;
        setExpandedCategoryId(newExpandedCategoryId);

        // Notify parent to handle the category selection
        if (newExpandedCategoryId) {
            onCategorySelect(category);
        }
    };

    // Toggle Sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible((prevState) => !prevState); // Toggle between visible and hidden
    };

    useEffect(() => {
        // Trigger slide-in animation on component mount
        setIsVisible(true);

        // Cleanup logic if needed
        return () => {};
    }, []);

    return (
        <>
            <div
                ref={sidebarRef} // Reference to the sidebar container
                className={`side-navbar ${isSidebarVisible ? 'visible' : 'hidden'} ${isVisible ? 'slide-in' : ''}`}
            >
                {categories.map((category) => (
                    <div key={category.id}>
                        <div
                            onClick={() => {
                                toggleCategory(category);
                            }}
                            className={selectedCategory?.id === category.id ? 'selected-category' : ''}
                        >
                            <img
                                src="../../icons/triangle.png"
                                alt="Toggle"
                                className={expandedCategoryId === category.id ? 'rotated' : ''}
                            />
                            <header>{category.name}</header>
                        </div>
                        {expandedCategoryId === category.id && (
                            <div className="subcategories expanded">
                                {category.subCategories.map((subCategory) => (
                                    <span
                                        key={subCategory.id}
                                        onClick={() => {
                                            onSubCategorySelect(subCategory);
                                            setIsSidebarVisible(false); // Close sidebar when subcategory is clicked
                                        }}
                                    >
                                        - {subCategory.name}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div
                className={`arrow ${isSidebarVisible ? 'rotated-arrow' : ''}`}
                onClick={toggleSidebar} // Sidebar toggled by click
            >
                <img src="../../icons/arrow.png" alt="Toggle Sidebar" />
            </div>
        </>
    );
}
