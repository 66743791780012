import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import UnderConstruction from './components/UnderConstruction/UnderConstruction'
import Home from './components/Home/Home';
import Products from './components/Products/Products';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Decors from './components/Products/Product/Decors/Decors';
import DecorDetails from './components/Products/Product/Decors/Decor/DecorDetails/DecorDetails';
import WhatsappComponent from './components/WhatsappComponent/WhatsappComponent';
import AboutUs from './components/AboutUs/AboutUs';
import Gallery from './components/Gallery/Gallery';
import Login from './components/Login/Login';
import BackOfficeHome from './components/BackOffice/BackOfficeHome/BackOfficeHome';

function App() {
  return (
    <Router>
      <Header />
      <AppContent />
      <Footer />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  // Determine if WhatsApp component should be visible
  const isWhatsappVisible = !(location.pathname === "/admin" || location.pathname === "/backOfficeHome");

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:categoryName/:subCategoryName" element={<Products />} />
        <Route path="/decors/:categoryName/:subCategoryName/:id" element={<Decors />} />
        <Route path="/DecorDetails/:categoryName/:subCategoryName/:modelName/:id/:modelId" element={<DecorDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/aboutUs" element={<UnderConstruction />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/backOfficeHome" element={<BackOfficeHome />} />
      </Routes>
      {isWhatsappVisible && <WhatsappComponent />}
    </>
  );
}

export default App;
