import { Link } from 'react-router-dom';
import './Login.css';

export default function Login(){
    return(
        <div className="login">
            <div>
                <div>
                    <img src="./images/logos/admin.webp" />
                </div>
                <div>
                    <span>Admin Login</span>
                    <input type="text" placeHolder="Login"/>
                    <input type="password" placeHolder="Password"/>
                    <Link to={"/backOfficeHome"}><span>Connecter</span></Link>
                </div>
            </div>
        </div>
    );
}