import ProductsList from '../../Products/ProductsList/ProductsList';
import './BackOfficeProducts.css';

export default function BackOfficeProducts({ modelsBySubCategory, categoryName, subCategoryName }) {
    const handleEdit = (model) => {
        console.log('Edit model:', model);
        alert('Le Model ' + model.name + ' va etre modifier');
        // Add logic to open an edit form or modal
    };

    const handleDelete = (modelId) => {
        console.log('Delete model with id:', modelId);
        alert('Le Model ' + modelId + ' va etre supprimer');
        // Add logic to confirm and delete the model
    };

    return (
        <div className="back-office-products">
            <ProductsList
                modelsBySubCategory={modelsBySubCategory}
                categoryName={categoryName}
                subCategoryName={subCategoryName}
                isBackOffice={true}
                onModelEdit={handleEdit}
                onModelDelete={handleDelete}
            />
        </div>
    );
}
