import './WhatsappComponent.css';

export default function WhatsappComponent(){

    return(
        <a href='https://wa.me/+212650028139' target='_blank'>
            <div className='whatsapp'>
                <img src='../../../icons/whatsapp.png' alt='whatsApp'/>
            </div>
        </a>
    );
}