
import Model from "../models/Model";
import Product from "../models/Product";


const office = "bureau";
const education = "Education";
const school = "Ecole"
const house = "Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"

//Concept neo
const centro = new Product(1, 'centro', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/centro/centro.png', '../images/models/concept-neo/centro/ambiance-centro.png', '', 'PRK601', "Optez pour un parquet classique qui allie élégance et chaleur naturelle. Durable, esthétique et facile à entretenir, il s'intègre harmonieusement dans tous les styles d'intérieur, apportant une touche intemporelle à votre espace de vie.", [office, house]);
const moderna = new Product(2, 'moderna', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/moderna/moderna.png', '../images/models/concept-neo/moderna/ambiance-moderna.png', '', 'PRK604', "Optez pour un parquet classique qui allie élégance et chaleur naturelle. Durable, esthétique et facile à entretenir, il s'intègre harmonieusement dans tous les styles d'intérieur, apportant une touche intemporelle à votre espace de vie.", [office, house]);
const scala = new Product(3, 'scala', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/scala/scala.png', '../images/models/concept-neo/scala/ambiance-scala.png', '', 'PRK603', "Optez pour un parquet classique qui allie élégance et chaleur naturelle. Durable, esthétique et facile à entretenir, il s'intègre harmonieusement dans tous les styles d'intérieur, apportant une touche intemporelle à votre espace de vie.", [office, house]);
const portello = new Product(4, 'portello', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/portello/portello.webp', '../images/models/concept-neo/portello/ambiance-portello.webp', '','PRK608', "Optez pour un parquet classique qui allie élégance et chaleur naturelle. Durable, esthétique et facile à entretenir, il s'intègre harmonieusement dans tous les styles d'intérieur, apportant une touche intemporelle à votre espace de vie.", [office, house]);
const lima = new Product(5, 'lima', '1195 mm', '154.5 mm', '10 mm', 'Chêne huilé', '../images/models/concept-neo/lima/lima.png', '../images/models/concept-neo/lima/ambiance-lima.png', '', 'PRK606', "Optez pour un parquet classique qui allie élégance et chaleur naturelle. Durable, esthétique et facile à entretenir, il s'intègre harmonieusement dans tous les styles d'intérieur, apportant une touche intemporelle à votre espace de vie.", [office, house]);

//Effect
const elbruz = new Product(6, 'Elbruz', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/elbruz.webp', '../images/models/effect/ambiance-elbruz.webp', '', 'PRK911', "Le parquet Elbruz apporte la beauté magnifique des montagnes enneigées dans votre maison, apportant l'atmosphère pure et impressionnante de la nature à votre espace de vie. Alors que vous embarquez pour un voyage vers les sommets couverts de glaciers, il ajoute une touche esthétique à vos espaces.", [house, office]);
const rosso = new Product(7, 'Rosso', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/rosso.webp', '../images/models/effect/ambiance-rosso.webp', '', 'PRK909', "Rosso Parquet propose une solution de décoration unique conçue et inspirée des terres intactes de la nature. Avec sa couleur et sa texture reflétant les brises chaudes du coucher du soleil, c'est un choix idéal pour ajouter de la chaleur et du naturel à votre espace. Ce modèle de parquet unique crée une atmosphère paisible dans votre maison ou votre lieu de travail. Redécouvrez vos espaces de vie avec le modèle Parquet Rosso, inspiré de la beauté intacte de la nature.", [house, office]);
const taurus = new Product(8, 'Toros', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/taurus.webp', '../images/models/effect/ambiance-taurus.webp', '', 'PRK901', "Apportez la richesse unique des tons terre et végétaux à vos espaces de vie ! Ce Parquet vous permet de découvrir les magnifiques couleurs et textures de la nature dans votre maison ou votre lieu de travail. Cette collection unique reflète la vitalité et la chaleur de la nature à l'intérieur, créant une atmosphère sereine dans vos espaces de vie. Le parquet Toros, où vous ressentirez la beauté de la nature à chaque pas, complète parfaitement vos espaces de vie avec une apparence élégante et une base solide.", [house, office]);
const ural = new Product(9, 'Ural', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/ural.webp', '../images/models/effect/ambiance-ural.webp', '', 'PRK907', "Le revêtement de sol stratifié Ural, un ajout harmonieux à la palette invitante de tons de terre chauds de la collection Effect 8mm, offre une occasion unique de vous immerger dans la beauté de la nature à chaque instant. À chaque pas franchi dans l'Oural, vous ressentez un sentiment distinct de connexion avec le monde naturel, insufflant à votre maison une chaleur réconfortante et un véritable sentiment d'authenticité.", [house, office]);
const fuji = new Product(10, 'Fuji', '1200 mm', '191 m', '8 m', 'Register Emboss', '../images/models/effect/fuji.webp', '../images/models/effect/ambiance-fuji.webp', '', 'PRK905', "Fuji Parquet apporte l'attrait enchanteur du coucher de soleil dans vos espaces de vie, vous permettant de créer une atmosphère naturelle avec les tons où le soleil rencontre la terre. Fuji évoque une apparence et une sensation qui vous font presque ressentir la chaleur de la nature dans votre maison ou votre lieu de travail. Avec des tons reflétant la palette de couleurs du coucher de soleil, il vous invite à embrasser les bras de la nature à chaque pas. Transformez votre espace en un environnement chaleureux, accueillant et esthétique avec le parquet Fuji Effect 8MM.", [house, office]);

//Nature Line
const danube = new Product(11, 'Danube', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/danube.webp', '../images/models/nature-line/ambiance-danube.webp', '', 'PRK505', 'La danse du bois avec la technologie ! Ressentez le rythme du pays où la valse est née et la paix fluide du Danube chez vous.', [house, office]);
const trendOak = new Product(12, 'Trend Oak', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/trend-oak.webp', '../images/models/nature-line/ambiance-trend-oak.webp', '', 'PRK501', "Une décoration innovante qui ouvre l'horizon ! Créez une ambiance différente dans vos espaces de vie avec sa teinte naturelle et chaleureuse qui change des tendances.", [house, office]);
const rhodes = new Product(13, 'Rhodes', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/rhodes.webp', '../images/models/nature-line/ambiance-rhodes.webp', '', 'PRK509', "Une décoration innovante qui ouvre l'horizon ! Créez une ambiance différente dans vos espaces de vie avec sa teinte naturelle et chaleureuse qui change des tendances.", [house, office]);
const talya = new Product(14, 'Talya', '1200 mm', '191 m', '8 m', 'Natura', '../images/models/nature-line/talya.webp', '../images/models/nature-line/ambiance-talya.webp', '', 'PRK508', "Le printemps, c'est le réveil de la nature ! Ressentez les nuances du printemps et les couleurs vibrantes de la nature dans vos espaces de vie.", [house, office]);

//Pruva 
const cenova = new Product(15, 'Cenova', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/cenova.webp', '../images/models/pruuva/ambiance-cenova.webp', '', 'PRK224', "Un chêne exquis de Cenova, la patrie des Génois. L'effet du ton chêne clair du motif à chevrons vous est partagé à travers l'interprétation Cenova.", [house, office]);
const parima = new Product(16, 'Parima', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/parima.webp', '../images/models/pruuva/ambiance-parima.webp', '', 'PRK225', "Ornez votre espace avec la finesse d'une corde. Inspiré des cordages des marins, Parima reflète sa personnalité élégante et sa forte présence dans votre espace.", [house, office]);
const patalya = new Product(17, 'Patalya', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/patalya.webp', '../images/models/pruuva/ambiance-patalya.webp', '', 'PRK226', "Une interprétation unique avec le motif Chevron. Faites la différence dans vos sols avec le décor Patalya de la série Pruva.", [house, office]);
const licya = new Product(18, 'Licya', '1200 mm', '188 m', '8 mm', 'Natura', '../images/models/pruuva/licya.webp', '../images/models/pruuva/ambiance-licya.webp', '', 'PRK215', "Faites l'expérience du naturel dans votre espace avec Lycian inspiré de la nature unique et de l'histoire de la Méditerranée.", [house, office]);

//Bella
const acasia = new Product(19, 'Acasia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/acasia.webp', '../images/models/bella/ambiance-acasia.webp', '', 'PRK103', "Chaleureux, cosy et naturel ! Explorez la nature dans votre espace avec les lignes délicates et distinctives des tons terre.", [house]);
const daphnia = new Product(20, 'Daphnia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/daphnia.webp', '../images/models/bella/ambiance-daphnia.webp', '', 'PRK108', "Il est temps de se rapprocher de la nature ! Reflétez les nuances uniques de la nature sur votre espace.", [house]);
const gardenia = new Product(21, 'Gardenia', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/gardenia.webp', '../images/models/bella/ambiance-gardenia.webp', '', 'PRK109', "Place à l'élégance ! Ajoutez des touches délicates à vos espaces de vie avec sa décoration chaleureuse et lumineuse.", [house]);
const verona = new Product(22, 'Verona', '1200 mm', '191 m', '8 mm', 'Viva Wood', '../images/models/bella/verona.webp', '../images/models/bella/ambiance-verona.webp', '', 'PRK1110', "Une section de la ville de l'amour éternel de Roméo et Juliette ! Les nuances de gris et de blanc des Arènes de Vérone dans votre espace.", [house]);


//Atlantic 
const classicOak = new Product(23, 'Classic Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/classic-oak.webp', '../images/models/atlantic/ambiance-classic-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '066L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const goldenOak = new Product(24, 'Golden Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/golden-oak.webp', '../images/models/atlantic/ambiance-golden-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '977L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const naturlaOak = new Product(25, 'Natural Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/natural-oak.webp', '../images/models/atlantic/ambiance-natural-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '236L', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const oakChevron = new Product(26, 'Oak Chevron', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/oak-chevron.webp', '../images/models/atlantic/ambiance-oak-chevron.webp', '../images/models/atlantic/classic-oak-ts.png', '369M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const oakChevron666 = new Product(27, 'Oak Chevron', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/oak-chevron666.webp', '../images/models/atlantic/ambiance-oak-chevron666.webp', '../images/models/atlantic/classic-oak-ts.png', '666D', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const bartekOak = new Product(28, 'Bartek Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/bartek-oak.webp', '../images/models/atlantic/ambiance-bartek-oak.jpg', '../images/models/atlantic/classic-oak-ts.png', '266M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const goldenOak262 = new Product(29, 'Golden Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/golden-oak262.webp', '../images/models/atlantic/ambiance-golden-oak262.webp', '../images/models/atlantic/classic-oak-ts.png', '262M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const chardonnay = new Product(30, 'Chardonnay', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/chardonnay.webp', '../images/models/atlantic/ambiance-chardonnay.webp', '../images/models/atlantic/classic-oak-ts.png', '629M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const silkOak = new Product(31, 'Silk Oak', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/silk-oak.webp', '../images/models/atlantic/silk-oak.webp', '../images/models/atlantic/classic-oak-ts.png', '639M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);
const zinc = new Product(32, 'Zinc  ', '+/- 32 m', '2 - 3 m', '1.90 mm', 'Oak', '../images/models/atlantic/zinc.webp', '../images/models/atlantic/ambiance-zinc.webp', '../images/models/atlantic/classic-oak-ts.png', '993M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house]);


//Turbo
const veniceTile = new Product(33, 'Venice Tile', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/venice-tile.webp', '../images/models/turbo/ambiance-venice-tile.webp', '../images/models/turbo/turbo-ts.png', '961M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const lion = new Product(34, 'Lion', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/lion.webp', '../images/models/turbo/ambiance-lion.webp', '../images/models/turbo/turbo-ts.png', '2303', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const cameo = new Product(35, 'Cameo', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/cameo.webp', '../images/models/turbo/ambiance-cameo.webp', '../images/models/turbo/turbo-ts.png', '1300', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const aveo = new Product(36, 'Aveo', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aveo.webp', '../images/models/turbo/ambiance-aveo.webp', '../images/models/turbo/turbo-ts.png', '090S', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const agedOak = new Product(37, 'Aged Oak', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aged-oak.webp', '../images/models/turbo/ambiance-aged-oak.webp', '../images/models/turbo/turbo-ts.png', '967M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
const agedOak619 = new Product(38, 'Aged Oak', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/aged-oak619.webp', '../images/models/turbo/aged-oak619.webp', '../images/models/turbo/turbo-ts.png', '619M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, office]);
//uni
const blue = new Product(39, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/blue.jpg', '../images/models/turbo/ambiance-uni.jpg', '../images/models/turbo/turbo-ts.png', '772M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);
const green = new Product(40, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/green.jpg', '../images/models/turbo/ambiance-uni.jpg', '../images/models/turbo/turbo-ts.png', '524M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);
const orange = new Product(41, 'Solid', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/turbo/orange.jpg', '../images/models/turbo/ambiance-orange.jpg', '../images/models/turbo/turbo-ts.png', '233M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Découvrez tous les atouts de nos rouleaux de vinyle et transformez vos espaces dès aujourd'hui.", [house, school]);

//Enfant
const roadTrip = new Product(980, 'Roadtrip ', 0, 0, 0, '', '../images/models/enfant/roadtrip.webp', '../images/models/enfant/ambiance-roadtrip.webp', '../images/models/turbo/turbo-ts.png', '547M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Parfaits pour les espaces de vie animés, ils sont également adaptés aux chambres d’enfants grâce à leur surface facile à nettoyer et résistante aux petits accidents du quotidien. Découvrez tous les atouts de nos rouleaux de vinyle et créez un espace chaleureux pour toute la famille.", [house, school]);
const enfant = new Product(42, 'Enfant', '+/- 30 m', '2 - 3 m', '2.30 mm', '', '../images/models/enfant/ambiance-enfant.jpeg', '../images/products/gerflex.jpg', '../images/models/turbo/turbo-ts.png', '547M', "Nos magnifiques rouleaux de vinyle sont la solution idéale si vous recherchez un revêtement de sol simple à poser, abordable et entièrement imperméable. Disponibles en largeurs de 2 à 3 mètres, ils représentent l'option de revêtement de sol la plus pratique et économique. Parfaits pour les espaces de vie animés, ils sont également adaptés aux chambres d’enfants grâce à leur surface facile à nettoyer et résistante aux petits accidents du quotidien. Découvrez tous les atouts de nos rouleaux de vinyle et créez un espace chaleureux pour toute la famille.", [house, school]);


//gazon artificiel - decoratif
const decoratifGreen7mm = new Product(43, '7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/green.jpg', '../images/models/decoratif-7mm/ambiance-green.jpg', 'ts-img', 'Vert', "", [house]);
const decoratifBlue7mm = new Product(44, '7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/blue.jpg', '../images/models/decoratif-7mm/ambiance-blue.jpg', 'ts-img', 'Bleu', "", [house]);
const decoratifRed7mm = new Product(45, '7 mm/10 mm', '+/- 30 m', '2 - 3 m', '7 mm', '', '../images/models/decoratif-7mm/red.jpg', '../images/models/decoratif-7mm/ambiance-red.jpg', 'ts-img', 'Rouge', "", [house]);


//gazon artificiel - jardin
const jardin18mm = new Product(49, '18 mm', '+/- 30 m', '2 - 3 m', '18 mm', '', '../images/models/sport/iris.jpg', '../images/models/sport/iris.jpg', 'ts-img', '', "", [house, sports]);
const jardin20mm = new Product(50, '20 mm', '+/- 30 m', '2 - 3 m', '20 mm', '', '../images/models/jardin/jardin-20.jpg', '../images/models/jardin/jardin-20.jpg', 'ts-img', '', "", [house, sports]);
const jardin28mm = new Product(51, '28 mm', '+/- 30 m', '2 - 3 m', '28 mm', '', '../images/models/talia/talia-2.jpg', '../images/models/talia/ambiance-talia.jpg', 'ts-img', '', "", [house, sports]);
const jardin30mm = new Product(52, '30 mm', '+/- 30 m', '2 - 3 m', '30 mm', '', '../images/models/castor/castor-2.jpg', '../images/models/castor/ambiance-castor.jpg', 'ts-img', '', "", [house, sports]);


//tapis paillason - spagheti 100
const spaghetiRed = new Product(53, 'Rouge', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-red.jpg', '../images/models/spagheti/ambiance-spagheti-red.jpg', 'ts-img', 'Spaghetti', "", [house,]);
const spaghetiBlue = new Product(54, 'Bleu', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-blue.jpg', '../images/models/spagheti/ambiance-spagheti-blue.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiGreen = new Product(55, 'Vert', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-green.jpg', '../images/models/spagheti/ambiance-spagheti-green.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiBlack = new Product(56, 'Noir', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-black.jpg', '../images/models/spagheti/ambiance-spagheti-black.jpg', 'ts-img', 'Spaghetti', "", [house]);
const spaghetiGray = new Product(57, 'Gris', '1 m', '15 m', '12 mm', '', '../images/models/spagheti/spagheti-gris.jpg', '../images/models/spagheti/ambiance-spagheti-gris.jpg', 'ts-img', 'Spaghetti', "", [house]);

//Tapis paillason - zemat
const zematRed = new Product(58, 'Rouge', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-red.jpeg', '../images/models/zemat/ambiance-zemat-red.jpg', 'ts-img', 'ZMat', "", [house]);
const zematGreen = new Product(59, 'Vert', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-green.jpeg', '../images/models/zemat/ambiance-zemat-green.jpg', 'ts-img', 'ZMat', "", [house]);
const zematBlue = new Product(60, 'Bleu', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-blue.jpeg', '../images/models/zemat/ambiance-zemat-blue.jpg', 'ts-img', 'ZMat', "", [house]);
const zematBlack = new Product(61, 'Noir', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-black.jpeg', '../images/models/zemat/ambiance-zemat-black.jpg', 'ts-img', 'ZMat', "", [house]);
const zematTurq = new Product(69, 'Turquoise', '0,90 m', '15 m', '5 mm', '', '../images/models/zemat/zemat-turq.jpeg', '../images/models/zemat/ambiance-zemat-turq.jpg', 'ts-img', 'ZMat', "", [house]);

//Tapis paillason - honey
const honeyRed = new Product(62, 'Rouge', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-red.jpg', '../images/models/honey/ambiance-honey-red.jpg', 'ts-img', 'Honey', "", [house]);
const honeyGreen = new Product(63, 'Vert', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-green.jpg', '../images/models/honey/ambiance-honey-green.jpg', 'ts-img', 'Honey', "", [house]);
const honeyBlue = new Product(64, 'Bleu', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-blue.jpg', '../images/models/honey/ambiance-honey-blue.jpg', 'ts-img', 'Honey', "", [house]);
const honeyBlack = new Product(65, 'Noir', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-black.jpg', '../images/models/honey/ambiance-honey-black.jpg', 'ts-img', 'Honey', "", [house]);
const honeyGris = new Product(66, 'Gris', '0,90 m', '15 m', '3,8 mm', '', '../images/models/honey/honey-gris.jpg', '../images/models/honey/ambiance-honey-gris.jpg', 'ts-img', 'Honey', "", [house]);

//Tapis paillasson - aspro
const asproBlack = new Product(67, 'Noir', '1,20 m', '16 m', '2,2 mm', '', '../images/models/aspro/aspro-black.jpg', '../images/models/aspro/ambiance-aspro-black.jpg', 'ts-img', 'Aspro', "", [house]);
const asproGris = new Product(68, 'Gris', '1,20 m', '16 m', '2,2 mm', '', '../images/models/aspro/aspro-gris.jpg', '../images/models/aspro/ambiance-aspro-gris.jpg', 'ts-img', 'Aspro', "", [house]);

//Gerflex - Moyen traffic - blacktex 
const safira = new Product(69, "Safira", '+/- 30m', '2 - 3 m', '2,80 mm', "Oak", '../images/models/blacktex/safira.webp', '../images/models/blacktex/safira.webp', '../images/models/blacktex/blacktex.png', '974E', '', [house, commerce, storage])

//Gerflex - Moyen traffic - tex-style 
const pureOak = new Product(70, "Pure Oak", '+/- 30m', '2 - 3 m', '2,80 mm', "Oak", '../images/models/tex-style/pureOak.webp', '../images/models/tex-style/ambiance-pureOak.webp', '../images/models/tex-style/tex-style.png', '010L', '', [house, commerce])
const disa = new Product(82, "Disa", '+/- 30m', '2 - 3 m', '2,80 mm', "Oak", '../images/models/tex-style/disa.webp', '../images/models/tex-style/ambiance-disa.webp', '../images/models/tex-style/tex-style.png', '979M', '', [house, commerce])

//gerflex - grand traffic - xtreme
const mira990 = new Product(71, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/mira990.webp', '../images/models/xtreme/ambiance-mira990.webp', '../images/models/xtreme/mira990-ts.png', '990D', '', [house, commerce, storage, sports])
const mira690 = new Product(72, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/mira690.webp', '../images/models/xtreme/mira690.webp', '../images/models/xtreme/mira990-ts.png', '690D', '', [house, commerce, storage, sports])
const mira160 = new Product(78, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/mira160.webp', '../images/models/xtreme/mira160.webp', '../images/models/xtreme/mira990-ts.png', '160L', '', [house, commerce, storage, sports])

const havannaOak696 = new Product(73, "havanna Oak", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/havanna.webp', '../images/models/xtreme/ambiance-havanna.webp', '../images/models/xtreme/mira990-ts.png', '696L', '', [house, commerce, storage, sports])
const goldenOak690 = new Product(74, "Golden Oak", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/goldenOak.webp', '../images/models/xtreme/ambiance-goldenOak.webp', '../images/models/xtreme/mira990-ts.png', '690L', '', [house, commerce, storage, sports])
const mira440 = new Product(75, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/roxane.webp', '../images/models/xtreme/ambiance-roxane.webp', '../images/models/xtreme/mira990-ts.png', '440M', '', [house, commerce, storage, sports])
const mira707 = new Product(76, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/mira707.webp', '../images/models/xtreme/mira707.webp', '../images/models/xtreme/mira990-ts.png', '707M', '', [house, commerce, storage, sports])
const mira770 = new Product(77, "Mira", '+/- 22 m', '2 - 3 m', '2 mm', "", '../images/models/xtreme/mira770.webp', '../images/models/xtreme/mira770.webp', '../images/models/xtreme/mira990-ts.png', '770M', '', [house, commerce, storage, sports])

//gerflex - grand trafic - homogene
const bazlat = new Product(78, "Bazalt", '+/- 20 m', '2 m', '2 mm', "Homogène", '../images/models/homogene/bazalt.jpg', '../images/models/homogene/bazalt.jpg', '../images/models/xtreme/mira990-ts.png', '', '', [house, commerce, storage, sports])
const almond = new Product(79, "Almond", '+/- 20 m', '2 m', '2 mm', "Homogène", '../images/models/homogene/almond.jpg', '../images/models/homogene/ambiance-almond.jpg', '../images/models/xtreme/mira990-ts.png', '', '', [house, commerce, storage, sports])
const sky = new Product(80, "Sky", '+/- 20 m', '2 m', '2 mm', "Homogène", '../images/models/homogene/sky.jpg', '../images/models/homogene/ambiance-sky.jpg', '../images/models/xtreme/mira990-ts.png', '', '', [house, commerce, storage, sports])
const water = new Product(81, "Water", '+/- 20 m', '2 m', '2 mm', "Homogène", '../images/models/homogene/water.jpg', '../images/models/homogene/water.jpg', '../images/models/xtreme/mira990-ts.png', '', '', [house, commerce, storage, sports])
const koala = new Product(800, "Koala", '+/- 20 m', '2 m', '2 mm', "Homogène", '../images/models/homogene/koala.jpg', '../images/models/homogene/koala.jpg', '../images/models/xtreme/mira990-ts.png', '', '', [house, commerce, storage, sports])



//Bois exterieur - bambou 
const bambouMur = new Product(82, 'Bambou mur', '1,8 m', '140 mm', '18 mm', 'bois', '../images/models/bambou/bardage.jpg', '../images/models/bambou/ambiance-bardage-mur.jpg', '', '', '', []);
const bambouSol = new Product(83, 'Bambou sol', '1,8 m', '140 mm', '20 mm', 'bois', '../images/models/bambou/bambou-sol.jpg', '../images/models/bambou/ambiance-bardage-sol.jpg', '', '', '', []);

//Bois exterieur - Teak
const teak = new Product(84, 'Teak', '90 cm a 2,40 m', '95 mm', '21 mm', 'face lisse et antidérapant', '../images/models/teak/teak.png', '../images/models/teak/ambiance-teak.png', '', '', '', []);

//Bois exterieur - ipe
const ipe = new Product(85, 'IPE', '93 cm a 1,83 m', '90 mm', '19 mm', 'bois', '../images/models/ipe/ipe.png', '../images/models/ipe/ambiance-ipe.png', '', '', '', []);

//Composite
const composite = new Product(96, 'Composite', '1,8 m', '140 mm', '18 mm', 'bois', '../images/models/composite/composite.jpg', '../images/models/composite/ambiance-composite.webp', '', '', '', []);

//moquette - mambo
const mambo19 = new Product(86, 'Mambo 19', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/mambo-19.jpg', '../images/models/moquette/ambiance-mambo-19.jpg', '../images/models/moquette/ts-bambou.png', '19', '', []);
const mambo49 = new Product(87, 'Mambo 49', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/mambo-49.jpg', '../images/models/moquette/ambiance-mambo-49.jpg', '../images/models/moquette/ts-bambou.png', '49', '', []);
const mambo96 = new Product(88, 'Mambo 96', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/mambo-96.jpg', '../images/models/moquette/ambiance-mambo-96.jpg', '../images/models/moquette/ts-bambou.png', '96', '', []);
const mambo99 = new Product(89, 'Mambo 99', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/mambo-99.jpg', '../images/models/moquette/ambiance-mambo-99.jpg', '../images/models/moquette/ts-bambou.png', '99', '', []);

//moquette - medusa
const medusa90 = new Product(90, 'Medusa 19', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-90.jpg', '../images/models/moquette/ambiance-medusa-90.jpg', '../images/models/moquette/ts.png', '19', '', []);
const medusa33 = new Product(91, 'Medusa 33', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-33.jpg', '../images/models/moquette/ambiance-medusa-33.jpg', '../images/models/moquette/ts.png', '33', '', []);
const medusa77 = new Product(92, 'Medusa 77', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-77.jpg', '../images/models/moquette/ambiance-medusa-77.jpg', '../images/models/moquette/ts.png', '77', '', []);
const medusa11 = new Product(93, 'Medusa 11', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-11.jpg', '../images/models/moquette/ambiance-medusa-11.jpg', '../images/models/moquette/ts.png', '11', '', []);
const medusa21 = new Product(94, 'Medusa 21', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-21.jpg', '../images/models/moquette/ambiance-medusa-21.jpg', '../images/models/moquette/ts.png', '21', '', []);
const medusa99 = new Product(95, 'Medusa 99', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-99.jpg', '../images/models/moquette/ambiance-medusa-99.jpg', '../images/models/moquette/ts.png', '99', '', []);
const medusa84 = new Product(96, 'Medusa 84', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/medusa-84.jpg', '../images/models/moquette/ambiance-medusa-84.jpg', '../images/models/moquette/ts.png', '84', '', []);

//moquette - basmat - autres
const basmat = new Product(97, 'Basmat', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/basmat.png', '../images/models/moquette/ambiance-basmat.png', '', '', '', []);
const antracite = new Product(99, 'Star', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/antracite.png', '../images/models/moquette/ambiance-antracite.png', '', '', '', []);
const chico = new Product(100, 'Chico Red', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/diamante.png', '../images/models/moquette/ambiance-diamante.png', '', '', '', []);
const chicoBlack = new Product(101, 'Chico Black', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/diamante-black.png', '../images/models/moquette/diamante-black.png', '', '', '', []);
const chicoBrown = new Product(102, 'Chico Brown', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/diamante-brown.png', '../images/models/moquette/diamante-brown.png', '', '', '', []);

const red = new Product(1010, 'star rouge', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/red.jpg', '../images/models/moquette/red.jpg', '', '', '', []);
const gris = new Product(1011, 'star gris', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/gris.jpg', '../images/models/moquette/gris.jpg', '', '', '', []);
const yellow = new Product(1012, 'star jaune', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/yellow.jpg', '../images/models/moquette/yellow.jpg', '', '', '', []);

const baige = new Product(1013, 'star baige', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/baige.jpg', '../images/models/moquette/baige.jpg', '', '', '', []);
const all = new Product(1014, 'star ', '50 cm', '50 cm', '5,4 mm', '', '../images/models/moquette/all.jpg', '../images/models/moquette/all.jpg', '', '', '', []);


const bardageWhite = new Product(103, 'Gris', '160 mm ', '2900 mm', '24 mm', '', '../images/models/bardage/white.jpg', '../images/models/bardage/white.jpg', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);
const bardageNoire = new Product(104, 'Noir', '160 cm', '2900 cm', '24 mm', '', '../images/models/bardage/bardage-noire.jpg', '../images/models/bardage/bardage-noire.jpg', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);
const bardageGris = new Product(105, 'Gris foncé', '160 cm', '2900 cm', '24 mm', '', '../images/models/bardage/bardage-gris.jpg', '../images/models/bardage/ambiance-gris-clair.png', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);
const bardageJaune = new Product(106, 'Chêne', '160 cm', '2900 cm', '24 mm', '', '../images/models/bardage/bardage-jaune.jpg', '../images/models/bardage/ambiance-jaune.png', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);
const bardageMaron = new Product(107, 'Maron', '160 cm', '2900 cm', '24 mm', '', '../images/models/bardage/bardage-maron.jpg', '../images/models/bardage/ambiance-maron.png', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);
const bardageWhitee = new Product(108, 'Blanc', '160 cm', '2900 cm', '24 mm', '', '../images/models/bardage/bardage-white.jpg', '../images/models/bardage/bardage-white.jpg', '', '', 'Le bardage est une solution élégante et durable pour habiller et protéger vos façades extérieures. Il offre une isolation renforcée, résiste aux intempéries, et ajoute une touche esthétique à votre bâtiment', []);

//Stores 


//placo platre - faux plafond - platre
const enPlatre1 = new Product(109,'Soleil', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre2 = new Product(110,'Fissurée', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre3 = new Product(111,'Lisse', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre4 = new Product(112,'Grenelée', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre5 = new Product(113,'Semi Perforée', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre6 = new Product(114,'Tourbillon', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);
const enPlatre7 = new Product(115,'Helice', '600 mm','600 mm','20 mm','Platre','../images/models/plafond/turb.jpg','../images/models/plafond/turb.jpg','','','',[]);

//placo platre - faux plafond - en bois 
const enBois = new Product(116,'En Bois', '600 mm','600 mm','20 mm','Bois','../images/models/plafond/bois.jpg','../images/models/plafond/bois.jpg','','','',[]);

//placo platre - faux plafond - metalique
const metalique1 = new Product(117,'Metalique Blanc', '1200 mm','300 mm','20 mm','Metalique','../images/models/plafond/metalique-blanc.jpg','../images/models/plafond/ambiance-metalique.jpg','','','',[]);
const metalique2 = new Product(118,'Metalique Gris', '1200 mm','300 mm','20 mm','Metalique','../images/models/plafond/metalique-gris.jpg','../images/models/plafond/metalique-gris.jpg','','','',[]);

//placo platre - faux plafond - en fibre
const enFibre = new Product(119,'En Fibre Minérale', '600 mm','600 mm','20 mm','en fibre','../images/models/plafond/fibre.jpg','../images/models/plafond/fibre.jpg','','','',[]);

//parquet semi-massif
const chene = new Product(128,'Chene', '','','10 mm', '','../images/models/parquet/parquet-chene-contrecolle.jpg','../images/models/parquet/ambiance-parquet-chene-contrecolle.jpg','','','',[]);
const umbra = new Product(129,'Noyé', '','','10 mm', '','../images/models/parquet/frene-umbra.webp','../images/models/parquet/ambiance-frene-umbra.webp','','','',[]);

//massif
const massif = new Product(130,'Massif', '','','10 mm', '','../images/models/parquet/massif.jpg','../images/models/parquet/massif.jpg','','','',[]);
const massif2 = new Product(131,'Massif', '','','10 mm', '','../images/models/parquet/chevron-massif.jpg','../images/models/parquet/chevron-massif.jpg','','','',[]);
const massif3 = new Product(131,'Massif', '','','10 mm', '','../images/models/parquet/massif.webp','../images/models/parquet/massif.webp','','','',[]);

//waterproof
const lame = new Product(132,'40KN522.', '','','10 mm', '','../images/models/parquet/522.png','../images/models/parquet/ambiance-522.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);
const lame2 = new Product(133,'40KN782', '','','10 mm', '','../images/models/parquet/782.png','../images/models/parquet/ambiance-782.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);
const lame3 = new Product(1332,'60KN10', '','','10 mm', '','../images/models/parquet/10.png','../images/models/parquet/ambiance-10.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);
const lame4 = new Product(1333,'47KN63', '','','10 mm', '','../images/models/parquet/63.png','../images/models/parquet/ambiance-63.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);
const lame5 = new Product(1334,'40KN542', '','','10 mm', '','../images/models/parquet/542.png','../images/models/parquet/ambiance-542.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);
const lame6 = new Product(1335,'40KN792', '','','10 mm', '','../images/models/parquet/792.png','../images/models/parquet/ambiance-792.jpg','','',"Découvrez un revêtement innovant et respectueux de l'environnement, fabriqué à partir de 80 % de bois et entièrement sans PVC. Ce matériau utilise des ressources renouvelables, offrant une solution durable et écologique. Parfait pour les espaces modernes, il est imperméable, résistant aux animaux de compagnie, et extrêmement durable. De plus, son installation facile en fait un choix pratique et esthétique pour tous vos projets.",[]);


//placo platre - BA13
const lainRoche = new Product(120,'Laine de Roche', '1300 mm',' mm','50 mm','lisse','../images/models/plafond/laine-roche.png','../images/models/plafond/laine-roche.jpg','','','',[]);
const std = new Product(121,'Standard', '1200 mm',' mm','9,5 mm','lisse','../images/models/plafond/std.png','../images/models/plafond/std.jpg','','','',[]);
const hydro = new Product(122,'Hydrofuge', '1200 mm',' mm','13 mm','lisse','../images/models/plafond/hydro.png','../images/models/plafond/ambiance-hydro.jfif','','','',[]);
const coup = new Product(123,'Coup de Feu', '1200 mm',' mm','12,5 mm','lisse','../images/models/plafond/coup.png','../images/models/plafond/ambiance-coup.png','','','',[]);
const phonique = new Product(124,'Phonique', '1200 mm',' mm',' mm','lisse','../images/models/plafond/phinque.jpg','../images/models/plafond/ambiance-phonique.jfif','','','',[]);

//placo platre - Isolation
const lainVerre =  new Product(125,'Laine de Verre', '1200 mm',' mm',' mm','laine','../images/models/plafond/laine-verre.jpg','../images/models/plafond/ambiance-laine-verre.jpg','','','',[]);
const poly =  new Product(126,'Polystèrine', '1200 mm',' mm',' mm','laine','../images/models/plafond/polysterine.png','../images/models/plafond/polysterine.jpg','','','',[]);
const liege =  new Product(127,'Liège', '1200 mm',' mm','20 mm - 40 mm','laine','../images/models/plafond/liege.jpg','../images/models/plafond/liege.jpg','','','',[]);

const products = [lame3,lame4,lame5,lame6,baige,all,red,yellow,gris,lame,lame2,chene,massif,massif2,umbra,liege,enPlatre1,enPlatre2,enPlatre3,enPlatre4,enPlatre5,enPlatre6,enPlatre7,enBois,metalique1,metalique2,enFibre,lainRoche,std,hydro,coup,phonique,lainVerre,poly,bardageWhitee, bardageWhite, bardageNoire, bardageGris, bardageJaune, bardageMaron, chicoBrown, basmat, chico, chicoBlack, antracite, medusa84, mambo19, mambo49, mambo96, mambo99, medusa11, medusa21, medusa33, medusa77, medusa90, medusa99, composite, bambouMur, bambouSol, teak, ipe, disa, bazlat, almond, sky, water, koala, mira770, mira707, mira440, goldenOak690, havannaOak696, mira160, mira690, mira990, pureOak, safira, zematTurq, asproBlack, asproGris, honeyRed, honeyGreen, honeyBlue, honeyBlack, honeyGris, zematRed, zematGreen, zematBlue, zematBlack, spaghetiBlue, spaghetiGreen, spaghetiBlack, spaghetiGray, spaghetiRed, jardin18mm, jardin20mm, jardin28mm, jardin30mm, decoratifGreen7mm, decoratifBlue7mm, decoratifRed7mm, enfant, green, orange, blue, roadTrip, veniceTile, lion, cameo, aveo, agedOak, agedOak619, naturlaOak, oakChevron, oakChevron666, bartekOak, goldenOak262, chardonnay, silkOak, zinc, goldenOak, classicOak, verona, gardenia, daphnia, acasia, licya, patalya, parima, cenova, centro, moderna, scala, portello, lima, elbruz, rosso, taurus, ural, fuji, danube, trendOak, rhodes, talya];


const models = [
    //Parquet
    new Model(1, "Concept Neo", "./images/models/concept-neo/concept-neo.png", [moderna, portello, scala, lima, centro]),
    new Model(2, "Effect", "./images/models/effect/effect.png", [elbruz, rosso, taurus, ural, fuji]),
    new Model(3, "Nature Line", "./images/models/nature-line/nature-line.png", [danube, trendOak, rhodes, talya]),
    new Model(4, "Pruva", "./images/models/pruuva/pruuva.png", [cenova, parima, patalya, licya]),
    new Model(5, "Bella", "./images/models/bella/bella.png", [acasia, daphnia, gardenia, verona]),

    new Model(75, "chene", "./images/models/parquet/ambiance-parquet-chene-contrecolle.jpg",[chene] ),
    new Model(76, "Umbra", "./images/models/parquet/ambiance-frene-umbra.webp",[umbra] ),
    new Model(78, "Massif chene chevron", "./images/models/parquet/massif.webp",[massif3] ),
    new Model(79, 'WaterProof', './images/models/parquet/ambiance-522.jpg',[lame, lame2,lame3,lame4,lame5,lame6]),
    //massif
    new Model(77, "Massif chene", "./images/models/parquet/massif",[massif] ),
    
    //gerflex 
    new Model(6, "Atlantic", "./images/models/atlantic/atlantic.webp", [classicOak, goldenOak, naturlaOak, oakChevron, oakChevron666, bartekOak, goldenOak262, chardonnay, silkOak, zinc]),
    new Model(7, "Turbo", "./images/models/turbo/turbo.webp", [veniceTile, lion, cameo, aveo, agedOak, agedOak619, blue, orange, green]),
    new Model(8, "Enfant", "./images/products/gerflex.jpg", [roadTrip, blue, orange, green, enfant]),

    //gerflex - moyen traffic
    new Model(27, "Blacktex", "./images/models/blacktex/safira.webp", [safira]),
    new Model(28, "Tex-Style", "./images/models/tex-style/ambiance-pureOak.webp", [pureOak, disa]),

    //gerflex - grand traffic
    new Model(29, 'Xtreme', './images/models/xtreme/ambiance-mira990.webp', [mira770, mira707, mira440, goldenOak690, havannaOak696, mira690, mira160]),
    new Model(30, 'Homogène', './images/models/homogene/ambiance-almond.jpg', [almond, sky, bazlat, water, koala]),
    new Model(31, 'Homgène Gerflor'),
    //Gazon artificiel - Decoratif
    new Model(9, "7 mm", '', [decoratifGreen7mm, decoratifBlue7mm, decoratifRed7mm]),

    //Gazon artificiel - Jardin
    new Model(11, "18 mm", '', [jardin18mm]),
    new Model(12, "20 mm", '', [jardin20mm]),
    new Model(13, "28 mm", './images/models/talia/talia-2.jpg', [jardin28mm]),
    new Model(14, "30 mm", './images/models/castor/castor-2.jpg', [jardin30mm]),

    //Gazon artificiel - Sport
    new Model(16, " 40 mm", '', []),
    new Model(17, " 50 mm", '', []),

    //Grillage Gazonné
    new Model(32, '1 m', './images/models/grillage/grillage-1G.jpg', []),
    new Model(33, '1,5 m', './images/models/grillage/grillage-15G.jpg', []),
    new Model(34, '2 m', './images/models/grillage/grillage-2G.jpg', []),

    //Tapi Paillasson - spagheti
    //new Model(20, "1 m",'', [spaghetiBlue,spaghetiGreen,spaghetiBlack,spaghetiGray,spaghetiRed]),
    new Model(21, "1m - 1,20 m", '', [spaghetiBlue, spaghetiGreen, spaghetiBlack, spaghetiGray, spaghetiRed]),

    //Tapi Paillasson - zemat
    //new Model(22, "0,90 m",'', [zematRed,zematGreen,zematBlue,zematBlack,zematTurq]),
    new Model(23, "0,90m - 1,20 m", '', [zematRed, zematGreen, zematBlue, zematBlack, zematTurq]),

    //Tapi Paillasson - honey
    //new Model(24, "0,90 m",'', [honeyRed,honeyGreen,honeyBlue,honeyBlack,honeyGris]),
    new Model(25, "0,90m - 1,20 m", '', [honeyRed, honeyGreen, honeyBlue, honeyBlack, honeyGris]),

    //Tapis Pailasson - aspro
    new Model(26, "1,20 m", '', [asproBlack, asproGris]),

    //Tapis paillason - Moquette
    new Model(40, 'Medusa', './images/models/moquette/ambiance-medusa-11.jpg', [medusa77, medusa99, medusa84, medusa11, medusa21, medusa33, medusa90]),
    new Model(41, 'Mambo', './images/models/moquette/ambiance-mambo-49.jpg', [mambo19, mambo49, mambo96, mambo99]),
    new Model(42, 'Basmat', './images/models/moquette/ambiance-basmat.png', [basmat]),
    new Model(43, 'Autres', './images/models/moquette/ambiance-diamnate.png', [antracite, red, yellow, gris,baige,all, chico, chicoBlack, chicoBrown]),
    //Bois Exterieur
    new Model(35, 'sol', './images/models/bambou/ambiance-bardage-sol.jpg', [bambouSol]),
    new Model(36, 'mur', './images/models/bambou/ambiance-bardage-mur.jpg', [bambouMur]),

    //teak
    new Model(37, 'Teak', './images/models/teak/ambiance-teak.png', [teak]),

    //ipe
    new Model(38, 'IPE', './images/models/ipe/ambiance-ipe.png', [ipe]),

    //composite
    new Model(39, 'Composite', './images/models/composite/ambiance-composite', [composite]),

    //Papier Peint
    new Model(424),
    new Model(43),
    new Model(44),

    new Model(50, 'Liége', './images/models/bardage/ambiance-liege.jpg'),

    //spc
    new Model(51, 'SPC', './images/models/bardage/ambiance-spc.jpg'),

    //wpc - mdf
    new Model(52, 'Bardage', './images/models/bardage/ambiance-jaune.png', [bardageJaune, bardageMaron, bardageWhite, bardageNoire, bardageGris, bardageWhitee]),

    //Stores
    new Model(69, 'Verticale', './images/models/stores/verticale.webp'),
    new Model(70, 'En Rouleur', './images/models/stores/en-rouleur.jpg'),
    new Model(71, 'Jour et Nuit', './images/models/stores/day-night.jpg'),
    new Model(72, 'Black Out', './images/models/stores/black-out.jpg'),
    new Model(73, 'Wave', './images/models/stores/wave.webp'),
    new Model(74, 'Japonaise', './images/models/stores/japonaise.webp'),

    new Model(53, 'En Platre','', []),
    new Model(54, 'En Bois','', [enBois]),
    new Model(55, 'Metalique ','', [metalique1, metalique2]),
    new Model(56, 'En Fibre Minérale','', [enFibre]),
    new Model(57, 'En Lain de Roche','', [lainRoche]),

    //placo platre - BA-13
    new Model(58, 'STD','', [std]),
    new Model(59, 'hydrofuge','', [hydro]),
    new Model(60, 'Coupe Feu','', [coup]),
    new Model(61, 'Phonique','', [phonique]),
    new Model(62, 'Acoustique','', []),

    //placo platre - isolation
    //laine de roche
    new Model(66, 'Laine de Verre','', [lainVerre]),
    new Model(67, 'Polystyrène Extrudé','', [poly]),
    new Model(68, 'Liège','', [liege]),
    /*new Model(45, 'Design Id', '', []),
    new Model(46, 'Marburg', '', []),
    new Model(47, 'Novamur', '', []),
    new Model(48, 'Enfant', '', []),
    new Model(49, 'Eijffinger', '', [])*/
    new Model(991, 'PARQUET'),
    new Model(992, 'GAZON ARTIFICIEL'),
    new Model(993, 'PLACO PLATRE'),
    new Model(994, 'GERFLEX'),
    new Model(995, 'paillasson - Moquette'),
    new Model(996, 'Bois Extérieur',),
    new Model(997, 'Bardage'),
    new Model(998, 'store')
]

//massif = bois exterieur

//parquet contrecollé chene 10 mm
//parquet contrecollé frene umbra(noyé) 1092 mm / 330 mm / 14mm  0,99m2/paquet 

//massif 
//planche parquet massif chene 60*600*10 (14 mm et 20 mm a consulter) 

export default { products, models };