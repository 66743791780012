import './BackOfficeSideNavbar.css';

export default function BackOfficeSideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {
    return (
        <div className="back-office-side-navbar">
            <div>
                <img src="./images/avatar.webp" alt="Avatar" />
                <span>Mr. Tazi Taoufik</span>
            </div>
            <div>
                {categories.map((category) => (
                        <span onClick={() => onCategorySelect(category)}>{category.name}</span>
                ))}
            </div>
        </div>
    );
}
