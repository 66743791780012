import Model from '../Product/Model';
import ProductTitle from '../../ProductTitle/ProductTitle';
import './ProductsList.css';
import Title from '../../Title/Title';
import { useEffect } from 'react';

export default function ProductsList({
    modelsBySubCategory,
    categoryName,
    subCategoryName,
    isBackOffice = false,
    onModelEdit,
    onModelDelete,
}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="products-list">
            <ProductTitle categoryName={categoryName} subCategoryName={subCategoryName} path={'../../../'} />
            <div className='group'>
                {modelsBySubCategory.map((group) => (
                    <div key={group.subCategoryName}>
                        <Title content={group.subCategoryName} />
                        <div className="models-group">
                            {group.models.map((model) => (
                                <div key={model.id} className="backoffice-model">
                                    <Model
                                        model={model}
                                        categoryName={categoryName}
                                        subCategoryName={subCategoryName}
                                    />
                                    {isBackOffice && (
                                        <div className="backoffice-actions">
                                            <img src='./images/add.png' onClick={() => onModelEdit(model)}/>
                                            <img src='./images/delete.jpg' onClick={() => onModelDelete(model.id)}/>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
