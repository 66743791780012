import { Link } from 'react-router-dom';
import categories from '../../../data/Categories';
import Data from '../../../data/Models';
import './Model.css';

export default function Model({ model, categoryName }) {
    // Validate the model prop
    if (!model) {
        console.error("Model prop is undefined.");
        return null;
    }

    // Find the model in the global Data
    const tempModel = Data.models.find((m) => m.id === parseInt(model.id));
    if (!tempModel) {
        console.error(`No model found in Data.models with id ${model.id}`);
        return null;
    }

    // Default models mapping
    const defaultModelMapping = {
        991: { category: 'parquet', subCategory: 'stratifié' },
        992: { category: 'gazon artificiel', subCategory: 'decoratif' },
        993: { category: 'placo platre', subCategory: 'papierPeint' },
        994: { category: 'gerflex (vinyle)', subCategory: 'residentiel' },
        995: { category: 'tapis paillasson', subCategory: 'spaghetti' },
        996: { category: 'bois extérieur', subCategory: 'bambou' },
        997: { category: 'bardage - habillage', subCategory: 'wpc' },
        998: { category: 'stores', subCategory: 'stores' },
    };

    // External links mapping
    const externalLinks = {
        31: 'https://www.gerflor.ma/fr/produits/mipolam-affinity',
    };

    // Check if the model is part of default models
    const isDefaultModel = model.id in defaultModelMapping;

    // Find subcategory name for the model
    const findSubCategoryName = (categoryName, modelId) => {
        const category = categories.find(cat => cat.name === categoryName);
        if (category) {
            const subCategory = category.subCategories.find(sub => 
                sub.models.some(m => m.id === modelId)
            );
            return subCategory ? subCategory.name : null;
        }
        return null;
    };

    const subCategoryNameForModel = isDefaultModel
        ? defaultModelMapping[model.id].subCategory
        : findSubCategoryName(categoryName, model.id);

    // Validate products array
    const productId = tempModel.products && tempModel.products.length > 0 ? tempModel.products[0].id : null;

    // Construct the link path
    const linkPath = isDefaultModel
        ? `/products/${defaultModelMapping[model.id].category}/default`
        : `/DecorDetails/${categoryName}/${subCategoryNameForModel}/${model.name}/${productId}/${model.id}`;

    // Handle external links
    const handleClick = (e) => {
        if (model.id in externalLinks) {
            e.preventDefault();
            window.open(externalLinks[model.id], '_blank');
        }
    };

    // Render the component
    if (!productId && !isDefaultModel) {
        // Render a disabled div if productId is null and it's not a default model
        return (
            <div className="product disabled">
                <div>
                    <img src={'../../' + model.image} alt={model.name} />
                    <header>{model.name}</header>
                </div>
            </div>
        );
    }

    return (
        <Link key={model.id} to={linkPath} onClick={handleClick}>
            <div className="product">
                <div>
                    <img src={'../../' + model.image} alt={model.name} />
                    <header>{model.name}</header>
                </div>
            </div>
        </Link>
    );
}
