import { useState } from 'react';
import BackOfficeSideNavbar from '../BackOfficeSideNavbar/BackOfficeSideNavbar';
import BackOfficeProducts from '../BackOfficeProducts/BackOfficeProducts';
import './BackOfficeHome.css';
import categories from '../../../data/Categories';

export default function BackOfficeHome() {
    const [selectedCategory, setSelectedCategory] = useState(null); // Track selected category
    const [selectedSubCategory, setSelectedSubCategory] = useState(null); // Track selected subcategory

    // Handle category selection
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(null); // Clear subcategory when a new category is selected
    };

    // Handle subcategory selection
    const handleSubCategorySelect = (subCategory) => {
        setSelectedSubCategory(subCategory);
    };

    return (
        <div className="back-office-home">
            {/* Sidebar */}
            <BackOfficeSideNavbar
                categories={categories}
                selectedCategory={selectedCategory}
                onCategorySelect={handleCategorySelect}
                onSubCategorySelect={handleSubCategorySelect}
            />

            {/* Main Content */}
            <div className="back-office-content">
                {selectedCategory ? (
                    <BackOfficeProducts
                        modelsBySubCategory={selectedCategory.subCategories}
                        categoryName={selectedCategory.name}
                        subCategoryName={selectedSubCategory?.name || ''}
                    />
                ) : (
                    <div className="placeholder">
                        <h2>Welcome to the BackOffice!</h2>
                        <p>Select a category from the sidebar to begin.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
